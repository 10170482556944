<template>
  <div v-show="!isLoading">
    <b-row>
      <b-col cols="12" md="4">
        <router-link
          :to="'/kelurahan-suara/kelurahan/' + detail.village.id + '/input-suara/calon/2'"
          class="text-decoration-none"
        >
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dpr.jpeg"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPR </p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link :to="'/kelurahan-suara/kelurahan/' + detail.village.id + '/input-suara/calon/2'" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dprd.png"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPRD TK I</p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
      <b-col cols="12" md="4">
        <router-link :to="'/kelurahan-suara/kelurahan/'+ detail.village.id +'/input-suara/calon/3'" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              <img
                src="/assets/images/logo-dprd.png"
                height="200"
                alt="Logo DPR"
              />
              <p class="my-3 h4">DPRD TK II</p>
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: {
        village: [],
      },
      isLoading: true
    };
  },
  mounted() {
    this.whenMounted();
  },
  watch: {},
  methods: {
    async whenMounted() {
      await this.showLoading("Memuat data...");
      try {
        const resp = await this.$http.get(
          process.env.VUE_APP_API_URL + 'area/villages/' + this.$route.params.idKelurahan
        );
        this.detail.village = resp.data.data
        this.isLoading = false
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
    },
    async showLoading(text = "Memuat Data...") {
      await this.$swal.fire({
        title: "Mohon Tunggu",
        text: text,
        timer: 500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return Promise.resolve("success");
    },
    async openAlert() {
      await this.$swal
        .fire({
          title: "Perhatian!",
          text: "Pastikan anda mengisi data dengan benar dan teliti.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Mulai",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            this.$router.push(
              `/input-suara/calon/${this.$route.params.level}/form`
            );
          }
        });
    },
  },
};
</script>